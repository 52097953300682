import styled from "styled-components";
import { Link as LinkGatsby } from "gatsby";

export const Container = styled.nav`
  width: 100%;
  height: 116px;

  transition: all 0.2s ease;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);

  @media (max-width: 1200px) {
    padding: 0 32px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 116px;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 860px) {
    flex-direction: column;
    padding-top: 0;

    display: ${(props) => (props.show ? "block" : "none")};

    > div {
      display: none;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;

  a {
    margin-left: 20px;
  }

  @media (max-width: 860px) {
    width: 100%;
    display: ${(props) => (props.show ? "flex" : "none")};
    background: rgba(0, 0, 0, 0.85);
    color: #fff;
    flex-direction: column;
    padding-top: 64px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 500;

    a {
      margin: 24px 0 0 0;
    }
  }
`;

export const Item = styled.li`
  list-style: none;
  display: inline-block;
  padding: 16px;

  @media (max-width: 860px) {
    display: block;
    text-align: center;
  }

  .active {
    border-bottom: solid 3px #e66000;
  }
`;

export const Link = styled(LinkGatsby)`
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;

  :hover {
    border-bottom: solid 3px #eaaf1f;
  }
`;

export const Toggle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  transition: all 0.2s ease;

  display: none;

  svg {
    position: absolute;
    top: 34px;
    right: 40px;
    z-index: 600;
    fill: ${(props) => (props.show ? "#fff" : "#414141")};
  }

  @media (max-width: 860px) {
    display: flex;
    padding-top: 32px;
    transition: all 0.2s ease;
  }
`;

export const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 200px;
  }

  @media (max-width: 860px) {
    img {
      width: 160px;
    }
  }
`;
