import React from "react";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import NavMenu from "../components/NavMenu";
import Footer from "../components/Footer";

const Page404 = () => {
  return (
    <Layout>
      <SEO title="Contato " />
      <NavMenu />
      <h1 style={{ textAlign: "center", padding: 72 }}>
        Página não encontrada!! Erro 404!!
      </h1>
      <Footer />
    </Layout>
  );
};

export default Page404;
